import {Route, Routes} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import SHome from './pages/SHome';
import Address from './pages/SHome/Address/address';
import ProductCheck from './pages/SHome/ProductCheck';
import HomeIndex from './pages/SHome/Home';
import Loading from './components/Loading';
import Login from './pages/SHome/Login';
import Register from './pages/SHome/Register';
import Service from './pages/SHome/Service';
import Contact from './pages/SHome/Contact';
import Profile from './pages/SHome/Profile';
import Lesson from './pages/SHome/Lesson';
import LessonSlug from './pages/SHome/Lesson/slug';
import ResetPass from './pages/SHome/ResetPass';
import Price from './pages/SHome/Price';

function App() {
  return (
    <>
      <ToastContainer/>
      {/* <Navbar/>
      <Alert/> */}
      {/* <Loading/> */}
      <Routes>
        {/* <Route path='/' element={<SHome/>}/> */}
        {/* <Route path='/login' element={<Login/>}/> */}
        {/* <Route path='/' element={<NewHome/>}/>
        <Route path='/profile' element={<NewProfile/>}/>
        <Route path='/register' element={<NewRegister/>}/>
        <Route path='/forgot' element={<Forgot/>}/> */}
        {/* <Route path='/service-procedure' element={<ServiceProcedure/>}/>
        <Route path='/lessons' element={<Lessons/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/forget-password' element={<ResetPass/>}/>
        <Route path='/activate/:token' element={<ActivatePage/>}/>
        <Route path='/address' element={<Address/>}/> */}

        <Route path="/" element={<SHome />}>
          <Route index element={<HomeIndex/>}/>
          <Route path="product-check" element={<ProductCheck/>}/>
          <Route path="/address" element={<Address/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/resetpass" element={<ResetPass/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/service" element={<Service/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/lesson" element={<Lesson/>}/>
          <Route path="/price" element={<Price/>}/>
          <Route path="/lesson/:id" element={<LessonSlug/>}/>
        </Route> 
        
      </Routes>
      {/* <Footer/> */}
    </>
  );
}

export default App;