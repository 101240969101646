import React from 'react';
import { PiNotebookFill } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import {AspectRatio} from '@chakra-ui/react'


const Contact = () => {
  return (
    <div className='text-white mt-8'>
         <div className='flex items-center gap-4'>
            {/* <TfiWrite /> */}
            <div>
                <h1 className='font-bold text-md uppercase text-xl'>Холбоо барих</h1>
                <p>Contact</p>
            </div>
        </div>

        <div className='mt-4'>
            <div className='flex justify-center'>
                <FiPhoneCall size={40}/>
            </div>
            <h1 className='text-center uppercase text-sm mt-4 font-bold'>Хаяг байршил :</h1>
            <p className='text-sm text-gray-600 text-center mt-4'>Зайсан 22р хороо 51-3 байр</p>
            <p className='text-center uppercase font-bold text-sm mt-4'>Оффисс утас: 9962-9419, 7748-9419</p>
            <p className='text-center uppercase font-bold text-sm mt-2'>Хүргэлт утас: 8813-7200</p>

            <div className='mt-4'>
            <AspectRatio ratio={16 / 18}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21404.99367954938!2d106.86595357431639!3d47.885602500000026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693eaceb7a5c3%3A0xcd2f19c8d32e7894!2zNTEvMyDQsdCw0LnRgCBiZXN0IGNhcmdv!5e0!3m2!1sen!2smn!4v1727001808573!5m2!1sen!2smn" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </AspectRatio>
            </div>
        </div>
      </div>
  )
}

export default Contact