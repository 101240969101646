import React, { useContext, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import Loading from '../../../components/Loading';
import { useToast } from '@chakra-ui/react';
import { GET, POST } from '../../../utils/requests';
import { authuri, getuseruri, resetPassUri, user_uri } from '../../../uri';

const ResetPass = () => {
  const nav = useNavigate();
  const [data, setData] = useState({phone_no:"", password:""});
  const {login} = useContext(AuthContext);
  const toast = useToast();
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(false);

  const register = () => {
    nav('/register');
  }

  const phonecheck = async() => {
    if(data.phone_no === ""){
      return toast({title: 'Алдаа',description: "Та утасны дугаараа оруулна уу!",status: 'warning',duration: 5000,isClosable: true});
    }
    setLoad(true);
    const res = await GET(authuri+`/check-phone?phone_no=${data.phone_no}`);
    if(res.status === 200){
      setStatus(true);
    }
    if(res.status === 204){
      setStatus(false);
      setLoad(false);
      return toast({title: 'Алдаа',description: "Ийм дугаартай харилцагч байхгүй байна!",status: 'warning',duration: 5000,isClosable: true});
    }
    setLoad(false);
  }

  const logins = async() => {
    if(data.password === ""){
      return toast({title: 'Алдаа',description: "Та нууц үгээ оруулна уу!",status: 'warning',duration: 5000,isClosable: true});
    }
    setLoad(true);
    const res = await POST({uri:authuri+'/resetpass', data});
    if(res.status === 200){
      toast({title: 'Амжилттай',description: "Таны нууц үг шинэчлэгдлээ",status: 'success',duration: 5000,isClosable: true});
      nav("/login");
    }
    setLoad(false);
  }

  return (
    <div className='font-Roboto text-white'>
      {
        load && <Loading/>
      }
      <div>
        <h1 className='text-white text-center mt-10 uppercase text-xl'>Нууц үг сэргээх</h1>
        <div className='mt-8'>

          {
            status ?
            <div>
              <h1 className='mb-2 text-gray-300'>Шинэ нууц үг</h1>
              <input onChange={(e) => setData({...data, password: e.target.value})} value={data.password} placeholder='Шинэ нууц үг оруулах' className='w-full
              p-4 rounded-xl outline-none bg-white text-black focus:ring-2' type='password'/>

              <div className='flex items-center mt-6'>
                <Link to="/login" className='uppercase text-md text-gray-300 w-80 xs:text-xs md:text-md'>Нэвтрэх ?</Link>
                <button onClick={logins} className='w-full border-2 p-4 rounded-full shadow-md shadow-[#7f7fd1] text-black font-bold
                hover:shadow-sm bg-gray-200'>Шинэчлэх</button>
              </div>
            </div>
            :
            <div>
              <h1 className='mb-2 text-gray-300'>Утасны дугаар</h1>
              <input onChange={(e) => setData({...data, phone_no: e.target.value})} placeholder='Утасны дугаар оруулах' className='w-full
              p-4 rounded-xl outline-none bg-white text-black focus:ring-2' type='number'/>

              <div className='flex items-center mt-6'>
                <Link to="/login" className='uppercase text-md text-gray-300 w-80 xs:text-xs md:text-md'>Нэвтрэх ?</Link>
                <button onClick={phonecheck} className='w-full border-2 p-4 rounded-full shadow-md shadow-[#7f7fd1] text-black font-bold
                hover:shadow-sm bg-gray-200'>Илгээх</button>
              </div>
            </div>
          }

          <div className='mt-16 flex items-center w-full'>
            <div className='border border-gray-300 w-full rounded-full'></div>
            <h1 className='text-center text-gray-300 text-md w-[800px]'>Шинээр бүртгэл үүсгэх</h1>
            <div className='border border-gray-300 rounded-full w-full'></div>
          </div>
          <button onClick={register} className='w-full border-2 p-4 rounded-full shadow-md shadow-white hover:shadow-sm mt-4'>Бүртгүүлэх</button>
        </div>
      </div>
    </div>
  )
}

export default ResetPass