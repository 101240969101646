import React, { useContext, useEffect, useState } from 'react'
import AddressProfile from './Address';
import { BiExit } from "react-icons/bi";
import AddressCard from './Address/card';
import { AuthContext } from '../../../context/auth';
import { Navigate } from 'react-router-dom';
import { GET } from '../../../utils/requests';
import { getuseruri } from '../../../uri';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import ExitModal from './exitModal';
import axios from 'axios';


const Profile = () => {
  const {currentUser, logout} = useContext(AuthContext);
  const [data, setData] = useState({user:{}, address:[]});
  const [load, setLoad] = useState(false);


  const Get = async () => {
    setLoad(true);
    try{
      const res = await axios.get(getuseruri+`?token=${currentUser}`);
      setData({...data, user:res.data.user, address: res.data.address});
      setLoad(false);
      console.log(res)
    }catch(error){
      setLoad(false);
      if(error.response.status === 444){
        logout();
      }
      console.log(error);
    }
  }

  const callback = () => {
    Get();
  }

  useEffect(() => {
    Get();
  }, [currentUser]);
  
  if(currentUser){
    return (
      <div className='font-Roboto text-white'>
          <div className='mt-6 bg-gray-300 rounded-xl p-2'>
              {
                load ?
                <SkeletonText noOfLines={1}/>
                :
                <h1 className='text-center uppercase font-bold text-gray-900 text-xl'>{data?.user?.username}</h1>
              }
              {
                load ?
                <SkeletonText noOfLines={1}/>
                :
                <h1 className='text-center uppercase font-bold text-gray-700 text-md'>{data?.user?.phone_no}</h1>
              }
          </div>
          <div className='flex justify-end'>
              <ExitModal/>
          </div>
          <div className='mb-20'>
            {
              load?
                <Skeleton className='h-20 mt-2' rounded="2xl"/>
              :
              <>
              {
                data.address.map((it, index) => {
                  return(
                    <AddressCard key={index} data={it} callback={callback}/>
                  )
                })
              }
              </>
            }
          </div>
          <AddressProfile callback={callback}/>
      </div>
    )
  }else{
    return <Navigate to='/login'/>
  }
}

export default Profile