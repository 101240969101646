import React, { useContext, useState } from 'react';
import { CiCirclePlus } from "react-icons/ci";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  Select,
  Textarea,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { city, district, khoroo } from './city';
import { POST } from '../../../../utils/requests';
import { getuseruri } from '../../../../uri';
import { AuthContext } from '../../../../context/auth';

const AddressProfile = ({callback}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {currentUser} = useContext(AuthContext);
  const [status, setStatus] = useState(1);
  const [data, setData] = useState({city:"", district:"", khoroo:"", address:""});
  const [dist, setDist] = useState([]);
  const [khor, setKhor] = useState([]);
  const toast = useToast();
  const [load, setLoad] = useState(false);

  const handleCall = () => {
    callback();
  }

  const handleCity = (e) => {
    setData({...data, city:e});
    const a = district.filter(it => it.city_id === Number(e));
    setDist(a);
    setStatus(2);
  }

  const handleDistrict = (e) => {
    setData({...data, district:e});
    const a = khoroo.filter(it => it.city_id === Number(data.city) && it.district_id === Number(e));
    if(a.length === 0){
      const b = khoroo.filter(it => it.city_id === 1 && it.district_id === 1);
      setKhor(b);
    }else{
      setKhor(a); 
    }
    setStatus(3);
  }

  const handleKhoroo = (e) => {
    setData({...data, khoroo:e});
  }

  const handleSubmit = async() => {
    setLoad(true);
    const citydata = city.filter(it => it.id === Number(data.city));
    const distdata = district.filter(it => it.id === Number(data.district));
    const khoroodata = khoroo.filter(it => it.id === Number(data.khoroo));
    if(data.city === "" || data.district === "" || data.khoroo === "" || data.address === ""){
      setLoad(false);
      return toast({title: 'Алдаа',description: "Таны мэдээлэл дутуу байна !",status: 'warning',duration: 5000,isClosable: true,});
    }
    
    const res = await POST({uri: getuseruri+`/address`, data: {
      city: citydata[0].name,
      district: distdata[0].name,
      khoroo: khoroodata[0].name,
      address: data.address,
      token: currentUser
    }});

    if(res.status === 200){
      setLoad(false);
      handleCall();
      onClose();
      return toast({title: 'Амжилттай',description: "Хаяг нэмэгдлээ",status: 'success',duration: 5000,isClosable: true,});
    }

    setLoad(false);

  }
  
  
  return (
    <div>
        <div className='flex justify-center'>
            <div onClick={onOpen} className='bg-[#c2a72f] bg-opacity-70 cursor-pointer flex items-center justify-center ring-2 ring-white gap-2 xs:min-w-[320px] md:min-w-[600px]
              rounded-xl fixed bottom-20 py-3'>
                <CiCirclePlus size={30}/>
                <h1>Гэрийн хаяг бүртгэх</h1>
            </div>
        </div>
        <Modal isOpen={isOpen} onClose={onClose} placement='bottom'>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Modal Title</ModalHeader> */}
            <ModalCloseButton />
            <ModalBody>
                <div className='mb-10 font-Roboto mt-8'>
                  <h1 className='font-bold mb-4'>Гэрийн хаяг бүртгэх</h1>

                    <h1 className='text-sm mb-2'>Байршил сонгох</h1>

                    <Select onChange={(e) => handleCity(e.target.value)}>
                      <option>Сонгох</option>
                      {
                        city.map((it, index) => {
                          return(
                            <option value={it.id} key={index}>{it.name}</option>
                          )
                        })
                      }
                    </Select>

                    {(status === 2 || status === 3) && (
                      <Select className='mt-2' onChange={(e) => handleDistrict(e.target.value)}>
                        <option>Сонгох</option>
                        {dist.map((it, index) => (
                          <option value={it.id} key={index}>{it.name}</option>
                        ))}
                      </Select>
                    )}

                    {(status === 3) && (
                      <Select className='mt-2' onChange={(e) => handleKhoroo(e.target.value)}>
                        <option>Сонгох</option>
                        {khor.map((it, index) => (
                          <option value={it.id} key={index}>{it.name}</option>
                        ))}
                      </Select>
                    )}

                    <Textarea value={data.address} onChange={(e) => setData({...data, address: e.target.value})} className='mt-2' placeholder='Дэлгэрэнгүй хаяг оруулах'/>

                    <div className='mt-6 flex justify-center text-white'>
                        {
                          load ?
                          <div onClick={handleSubmit} className='bg-[#47bd43] ring-2 ring-gray-300 flex items-center justify-center gap-2
                            rounded-xl py-3 w-full'>
                            <Spinner/>
                          </div>
                          :
                          <div onClick={handleSubmit} className='bg-[#349730] ring-2 ring-gray-300 flex items-center cursor-pointer justify-center gap-2
                              rounded-xl py-3 w-full'>
                              <CiCirclePlus size={30}/>
                              <h1>Гэрийн хаяг бүртгэх</h1>
                          </div>
                        }
                    </div>

                </div>
            </ModalBody>
          </ModalContent>
        </Modal>
    </div>
  )
}

export default AddressProfile;