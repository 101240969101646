import React from 'react';
import { IoIosHelpCircleOutline } from "react-icons/io";
import {Link} from 'react-router-dom';

const LessonCard = ({data}) => {
  return (
    <div>
     {
      data.type === "FACEBOOK" ?
      <a href={data.link} target="_blank" className='mt-2 font-Roboto text-black'>
        <div className='bg-gray-200 p-2 rounded-md'>
            <h1 className='text-lg mb-2'>{data.name}</h1>
            <IoIosHelpCircleOutline size={24}/>
        </div>
      </a>
      :
      <Link to={`/lesson/${data.id}`} className='mt-2 font-Roboto text-black'>
        <div className='bg-gray-200 p-2 rounded-md'>
            <h1 className='text-lg mb-2'>{data.name}</h1>
            <IoIosHelpCircleOutline size={24}/>
        </div>
      </Link>
     }
    </div>
  )
}

export default LessonCard