import React, { useState } from 'react'

const Loading = () => {
  return (
    <div className='flex justify-center z-50'>
        <div className='bg-black opacity-40 bottom-0 absolute xs:w-full md:w-[700px] h-[100%] z-50'>
          <div className='flex justify-center mt-[500px]'>
              <img src='../../gifs/load.gif' className='w-20'/>
          </div>
          <h1 className='text-center text-white mt-2'>Ачааллаж байна.</h1>
        </div>
    </div>
  )
}

export default Loading