import React, { useEffect, useState } from 'react';

const Price = () => {    
    
  return (
    <div className='text-white font-Roboto'>
        <div className='mt-10'>
            <h1 className='font-bold text-md uppercase text-xl'>Үнийн мэдээлэл</h1>
            <p className='text-sm text-gray-200'>Үнийн мэдээлэл</p>
        </div>
        <div className='mt-4 mx-2 text-lg'>
            <p>✅ 0-100гр ачаа (ээмэг зүүлт, утасны гэр) 500-1000₮ </p>
            <p>✅100гр-200гр ачаа 1500₮ </p>
            <p>✅ 200гр-1кг ачаа 2500₮ </p>
            <p>✅ Хйрцагтай бараа буюу гутал, цүнх нэх мэт хайрцаглаж боосон ачаа овроосоо хамаарч 3000-8000</p>
            <p>✅ Хөнгөн боловч овор ихтэй бараа овроороо бодогдоно. 1m3 нь 650Y</p>
            <p>Хүргэлт зайсанд 5000 бусад газарт байршил зайнаас хамаарч 7000</p>
        </div>
    </div>
  )
}

export default Price