import React, { useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast,
  } from '@chakra-ui/react'
import { BiExit } from 'react-icons/bi';
import { AuthContext } from '../../../context/auth';

const ExitModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {logout} = useContext(AuthContext);
    const toast = useToast();

    const logoutFunction = () => {
        logout();
        onClose();
        return toast({title: 'Амжилттай',description: "Та системээс гарлаа",status: 'success',duration: 5000,isClosable: true,});
      }


  return (
    <>
        <div onClick={onOpen}className='flex font-bold items-center gap-2 bg-gray-300 text-gray-800 mt-2 p-2 rounded-xl w-48 justify-center'>
            <BiExit size={24}/>
            <h1 className='uppercase'>Гарах</h1>
        </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Системээс гарах уу ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Болих
            </Button>
            <Button onClick={logoutFunction} variant='ghost'>Тийм</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ExitModal